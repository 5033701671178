import { asyncStart } from '../../../common';

export const ENABLE_VIEW_REPORTS_FOR_ALL_USERS = 'AdvancedSettings.enableViewReportsForAllUsers';
export const enableViewReportsForAllUsers = (val) => (dispatch) => {
  dispatch(asyncStart(ENABLE_VIEW_REPORTS_FOR_ALL_USERS));
  dispatch({
    type: ENABLE_VIEW_REPORTS_FOR_ALL_USERS,
    val,
  });
};

export const GET_DISTRICT_ADVANCED_SETTINGS = 'settings.getDistrictAdvancedSettings';
export const getDistrictAdvancedSettings = (profile) => (dispatch) => {
  dispatch(asyncStart(GET_DISTRICT_ADVANCED_SETTINGS));
  dispatch({
    type: GET_DISTRICT_ADVANCED_SETTINGS,
    profile,
  });
};

export const SAVE_DISTRICT_ADVANCED_SETTINGS = 'settings.saveDistrictAdvancedSettings';
export const saveDistrictAdvancedSettings = (profile, data) => (dispatch) => {
  dispatch(asyncStart(SAVE_DISTRICT_ADVANCED_SETTINGS));
  dispatch({
    type: SAVE_DISTRICT_ADVANCED_SETTINGS,
    profile,
    data,
  });
};
