import { combineEpics, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { asyncFinish } from '../../../common/+store/async';
import { commonApiCallFragment } from '../../../common/+store/utils/utils.api';
import {
  GET_INVITED_USERS,
  GET_ORG_USERS,
  CANCEL_INVITE_USER,
  INVITE_USER_TO_ORG,
  ACTIVATE_ACCOUNT,
  DEACTIVATE_ACCOUNT,
  UPDATE_USER_PROFILE,
  RESET_USER_PASSWORD,
  RESEND_INVITE_USER,
} from './ManageUsers.actions';
import {
  getOrgUsers,
  getInvitedUsers,
  inviteUserToOrg,
  cancelUserInvite,
  updateDeleteUser,
  resendUserInvite,
} from './ManageUsers.api';

const updateDeleteJobs = {
  activation: 'activation',
  updatePassword: 'updatePassword',
  updateProfile: 'updateProfile',
};

const inviteUserToOrgEpic = (action$) =>
  action$.pipe(
    ofType(INVITE_USER_TO_ORG),
    switchMap((action) =>
      from(inviteUserToOrg(action.userData)).pipe(commonApiCallFragment(action$, action, 'Inviting User to Org'))
    ),
    switchMap((results) => {
      if (results.success) {
        return of(
          asyncFinish(INVITE_USER_TO_ORG, {
            signUpCode: results.response.data.signUpCode,
            userData: results.response.data.userData,
          })
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const cancelUserInviteEpic = (action$) =>
  action$.pipe(
    ofType(CANCEL_INVITE_USER),
    switchMap((action) =>
      from(cancelUserInvite(action.signUpCode)).pipe(commonApiCallFragment(action$, action, 'Canceling invite'))
    ),
    switchMap((results) => {
      if (results.success) {
        return of(
          asyncFinish(CANCEL_INVITE_USER, {
            signUpCode: results.action.signUpCode,
          })
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const getOrgUsersEpic = (action$) =>
  action$.pipe(
    ofType(GET_ORG_USERS),
    switchMap((action) => from(getOrgUsers()).pipe(commonApiCallFragment(action$, action, 'Getting Org Users'))),
    switchMap((results) => {
      if (results.success) {
        return of(asyncFinish(GET_ORG_USERS, results.response.data.users));
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const getInvitedUsersEpic = (action$) =>
  action$.pipe(
    ofType(GET_INVITED_USERS),
    switchMap((action) =>
      from(getInvitedUsers()).pipe(commonApiCallFragment(action$, action, 'Getting Invited Users'))
    ),
    switchMap((results) => {
      if (results.success) {
        return of(asyncFinish(GET_INVITED_USERS, results.response.data.users));
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const updateUserProfileEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_USER_PROFILE),
    switchMap((action) =>
      from(
        updateDeleteUser({
          userData: action.userData,
          userUid: action.userUid,
          job: updateDeleteJobs.updateProfile,
        })
      ).pipe(commonApiCallFragment(action$, action, 'Updating User Profile'))
    ),
    switchMap((results) => {
      if (results.success) {
        return of(
          asyncFinish(UPDATE_USER_PROFILE, {
            userUid: results.action.userUid,
            userData: results.response.data,
          })
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const resetUserPasswordEpic = (action$) =>
  action$.pipe(
    ofType(RESET_USER_PASSWORD),
    switchMap((action) =>
      from(
        updateDeleteUser({
          password: action.password,
          userUid: action.userUid,
          job: updateDeleteJobs.updatePassword,
        })
      ).pipe(commonApiCallFragment(action$, action, 'Resetting User Password'))
    ),
    switchMap((results) => {
      if (results.success) {
        return of(
          asyncFinish(RESET_USER_PASSWORD, {
            userUid: results.action.userUid,
            userData: results.response.data,
          })
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const deactivateAccountEpic = (action$) =>
  action$.pipe(
    ofType(DEACTIVATE_ACCOUNT),
    switchMap((action) =>
      from(
        updateDeleteUser({
          isActivate: false,
          userUid: action.userUid,
          job: updateDeleteJobs.activation,
        })
      ).pipe(commonApiCallFragment(action$, action, 'Deactivating Account'))
    ),
    switchMap((results) => {
      if (results.success) {
        return of(
          asyncFinish(DEACTIVATE_ACCOUNT, {
            userUid: results.action.userUid,
            userData: results.response.data,
          })
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const activateAccountEpic = (action$) =>
  action$.pipe(
    ofType(ACTIVATE_ACCOUNT),
    switchMap((action) =>
      from(
        updateDeleteUser({
          isActivate: true,
          userUid: action.userUid,
          job: updateDeleteJobs.activation,
        })
      ).pipe(commonApiCallFragment(action$, action, 'Activating Account'))
    ),
    switchMap((results) => {
      if (results.success) {
        return of(
          asyncFinish(ACTIVATE_ACCOUNT, {
            userUid: results.action.userUid,
            userData: results.response.data,
          })
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const resendUserInviteEpic = (action$) =>
  action$.pipe(
    ofType(RESEND_INVITE_USER),
    switchMap((action) =>
      from(resendUserInvite(action.signUpCode)).pipe(commonApiCallFragment(action$, action, 'Resending invite'))
    ),
    switchMap((results) => {
      if (results.success) {
        return of(asyncFinish(RESEND_INVITE_USER));
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const epics = combineEpics(
  updateUserProfileEpic,
  resetUserPasswordEpic,
  deactivateAccountEpic,
  activateAccountEpic,
  getInvitedUsersEpic,
  getOrgUsersEpic,
  cancelUserInviteEpic,
  inviteUserToOrgEpic,
  resendUserInviteEpic
);

export default epics;
