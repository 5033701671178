import { combineReducers } from 'redux';
import {
  GET_SCHOOLS,
  UPDATE_SETTINGS,
  GET_DEFAULT_SETTINGS,
  GET_GRADE_SPAN_TABLE,
  GET_CCMR_MANUAL,
  GET_CCMR_INDICATORS,
  GET_GraduationData_INDICATORS,
  GET_DISTRICT_OR_SCHOOL_DATA,
} from './SchoolSettings.actions';
import { ASYNC_ERROR, ASYNC_FINISH, ASYNC_START } from '../../../common';

const initialStatus = {
  message: '',
  error: '',
};
const status = (state = initialStatus, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_SCHOOLS) {
        return Object.assign({}, state, {
          message: '',
          error: '',
        });
      }
      return state;
    case ASYNC_ERROR:
      return state;
    default:
      return state;
  }
};

const needsInit = (state = true, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
    case ASYNC_ERROR:
      if (action.model === GET_SCHOOLS) {
        return false;
      }
      return state;
    default:
      return state;
  }
};

const needsUpdate = (state = true, action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === GET_DEFAULT_SETTINGS) {
        return state;
      }
      return false;
    case ASYNC_FINISH:
      if (action.model === GET_DEFAULT_SETTINGS) {
        return false;
      }
      return false;
    case ASYNC_ERROR:
      return state;
    default:
      return state;
  }
};

const schoolList = (state = {}, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_SCHOOLS) {
        return action.data.schools;
      }
      if (action.model === UPDATE_SETTINGS) {
        return action.data.schools;
      }

      if (action.model === GET_DISTRICT_OR_SCHOOL_DATA) {
        const { data } = action;
        const schoolId = data && data.campusNumber ? data.campusNumber : data.districtNumber;
        if (schoolId) {
          return {
            ...state,
            [schoolId]: {
              ...(state[schoolId] || {}), // Retain existing school data if any
              ...data, // Update with new data
            },
          };
        }
      }
      return state;
    default:
      return state;
  }
};

const updateSettings = (state = [], action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === UPDATE_SETTINGS) {
        return action.data.message;
      }
      return state;
    default:
      return state;
  }
};

const defaultSettings = (state = {}, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_DEFAULT_SETTINGS) {
        return action.data.schools;
      }
      return state;
    default:
      return state;
  }
};

const gradeSpanTable = (state = {}, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_GRADE_SPAN_TABLE) {
        return action.data.gradeSpanTable;
      }
      return state;
    default:
      return state;
  }
};

const ccmrManual = (state = {}, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_CCMR_MANUAL) {
        return action.data;
      }
      return state;
    default:
      return state;
  }
};

const ccmrIndicators = (state = [], action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_CCMR_INDICATORS) {
        return action.data;
      }
      return state;
    default:
      return state;
  }
};

const graduationDataIndicators = (state = [], action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_GraduationData_INDICATORS) {
        return action.data;
      }
      return state;
    default:
      return state;
  }
};

const districtOrSchoolData = (state = [], action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_DISTRICT_OR_SCHOOL_DATA) {
        return action.data;
      }
      return state;
    default:
      return state;
  }
};

export const _reducer = combineReducers({
  status,
  needsInit,
  schoolList,
  updateSettings,
  defaultSettings,
  needsUpdate,
  gradeSpanTable,
  ccmrManual,
  ccmrIndicators,
  graduationDataIndicators,
  districtOrSchoolData,
});

export default 'SchoolSettings.reducers.js';
