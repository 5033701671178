import { combineEpics, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { asyncFinish } from '../../../common/+store/async';
import { commonApiCallFragment } from '../../../common/+store/utils/utils.api';
import {
  ENABLE_VIEW_REPORTS_FOR_ALL_USERS,
  GET_DISTRICT_ADVANCED_SETTINGS,
  SAVE_DISTRICT_ADVANCED_SETTINGS,
} from './AdvancedSettings.actions';
import { enableViewReportsForAllUsers } from './AdvancedSettings.api';
import { saveDistrictAdvancedSettings, getDistrictAdvancedSettings } from './AdvancedSettings.db';

const enableViewReportsForAllUsersEpic = (action$) =>
  action$.pipe(
    ofType(ENABLE_VIEW_REPORTS_FOR_ALL_USERS),
    switchMap((action) =>
      from(enableViewReportsForAllUsers(action)).pipe(commonApiCallFragment(action$, action, 'Enable View Reports'))
    ),
    switchMap((results) => {
      if (results.success) {
        return of(
          asyncFinish(ENABLE_VIEW_REPORTS_FOR_ALL_USERS, {
            result: results.action,
          })
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );
const saveDistrictAdvancedSettingsEpic = (action$) =>
  action$.pipe(
    ofType(SAVE_DISTRICT_ADVANCED_SETTINGS),
    switchMap((action) =>
      from(saveDistrictAdvancedSettings(action.profile, action.data)).pipe(
        commonApiCallFragment(action$, action, 'Save District Advanced Setting')
      )
    ),
    switchMap((results) => {
      if (results.success) {
        const firstKey = Object.keys(results.action.data.menusVisibility)[0];
        const firstValue = results.action.data.menusVisibility[firstKey];
        console.log(`${firstKey} = ${firstValue}`); // e.g., "reports = false"
        return of(
          asyncFinish(results.action.type, {
            message: 'Save District Advanced Setting',
            menuName: firstKey,
            isVisible: firstValue,
          })
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const getDistrictAdvancedSettingsEpic = (action$) =>
  action$.pipe(
    ofType(GET_DISTRICT_ADVANCED_SETTINGS),
    switchMap((action) =>
      from(getDistrictAdvancedSettings(action.profile)).pipe(
        commonApiCallFragment(action$, action, 'Get District Advanced Settings')
      )
    ),
    switchMap((results) => {
      if (results.success) {
        return of(asyncFinish(results.action.type, results.response));
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const epics = combineEpics(
  enableViewReportsForAllUsersEpic,
  getDistrictAdvancedSettingsEpic,
  saveDistrictAdvancedSettingsEpic
);
export default epics;
