import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Cancel';
import PersonAdd from '@material-ui/icons/PersonAdd';
import { ExportCSVButton, LoadingCircle, TitleBackButton, makeCsv } from '../../common';
import { saveAs } from 'file-saver';
import moment from 'moment-timezone';

import {
  Grid,
  IconButton,
  Typography,
  Paper,
  InputBase,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Button,
} from '@material-ui/core';
import styles from './ManageUsers.styles';
import User from './UserRow';
import UpdateAddUser from './UpdateAddUser';
import SearchIcon from '@material-ui/icons/Search';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import * as Fuse from 'fuse.js';

class ManageUsers extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    users: PropTypes.object,
    invitedUsers: PropTypes.object,
    init: PropTypes.func,
    status: PropTypes.shape({
      success: PropTypes.bool,
      message: PropTypes.string,
    }),
    profile: PropTypes.object,
  };
  constructor() {
    super();
    this.state = {
      currentInstance: this,
    };
  }
  state = {
    openInviteModal: false,
    order: 'asc',
    orderBy: 'displayName',
    initSearchUsers: false,
    initSearchUsersLoad: false,
    initSearchInvitedUsersLoad: false,
    searchedUsers: [],
    query: '',
    showInactive: false,
  };

  componentDidMount() {
    const { init } = this.props;
    init();
  }
  // Toggle function
  toggleInactiveUsers = () => {
    this.setState(
      (prevState) => ({
        showInactive: !prevState.showInactive,
      }),
      () => {
        this.onSearchClick(); // Ensure it runs AFTER state is updated
      }
    );
  };
  mergeUsers(users, invitedUsers) {
    var searchedUsersTmp = [];
    if (users) {
      Object.entries(users).forEach((item) => {
        const user = item[1];
        user.firebaseId = item[0];
        user.status = 'Active';
        if (user.lastLogin) {
          user.lastLogin = moment
            .utc(user.lastLogin)
            .tz('America/Chicago') // 'America/New_York'
            .format('MM-DD-YYYY hh:mm A'); // Convert to New York time 'MM-DD-YYYY hh:mm A z'
        }

        //const num = user.role === 'campusAdmin' ? user.campusNumber : user.districtNumber;
        // item[1].role=num;
        searchedUsersTmp.push(user);
      });
    }
    if (invitedUsers) {
      Object.entries(invitedUsers).forEach((item) => {
        const user = item[1];
        user.firebaseId = item[0];
        user.isInvitedUser = true;
        user.status = 'Invited';
        searchedUsersTmp.push(user);
      });
    }

    return searchedUsersTmp;
  }
  static getDerivedStateFromProps(props, state) {
    if (!state.initSearchUsersLoad || (!state.initSearchInvitedUsersLoad && (props.users || props.invitedUsers))) {
      var searchedUsersTmp = state.currentInstance.mergeUsers(props.users, props.invitedUsers);
      if (searchedUsersTmp != null && searchedUsersTmp.length > 0) {
        return {
          ...state,
          initSearchInvitedUsersLoad: props.invitedUsers != null,
          initSearchUsersLoad: props.users != null,
          order: 'desc',
          orderBy: 'displayName',
          searchedUsers: state.showInactive
            ? searchedUsersTmp // Show all users
            : searchedUsersTmp
            ? searchedUsersTmp.filter((user) => !user.deactivated)
            : [],
        };
      }
    }
    return state;
  }

  handleCloseModal = () => {
    this.setState({ openInviteModal: false });
  };

  handleOpenModal = () => {
    this.setState({ openInviteModal: true });
  };

  handleSort = (event, property) => {
    const { orderBy, order } = this.state;
    let newOrder = 'desc';
    if (orderBy === property && order === 'desc') {
      newOrder = 'asc';
    }
    this.setState({ order: newOrder, orderBy: property });
  };

  onSearchChange = (e) => {
    this.setState({ query: e.target.value });
  };

  onSearchClick = (e) => {
    const options = {
      shouldSort: true,
      threshold: 0.05,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['displayName', 'emailAddress', 'role', 'phoneNumber', 'status'],
    };
    const { users, invitedUsers } = this.props;

    const { query } = this.state;
    let searchedUsersTmp = this.mergeUsers(users, invitedUsers);
    const fuse = new Fuse(searchedUsersTmp, options);
    if (query) {
      searchedUsersTmp = fuse.search(query);
    }

    const searchedUsers = this.state.showInactive
      ? searchedUsersTmp // Show all users
      : searchedUsersTmp
      ? searchedUsersTmp.filter((user) => !user.deactivated)
      : [];

    this.setState({ searchedUsers: searchedUsers, initSearchUsers: !!query });
  };

  onSearchEnter = (e) => {
    if (e.key === 'Enter') {
      this.onSearchClick();
    }
  };
  onSortChange = (property) => (event) => {
    this.handleSort(event, property);
  };

  sortBy(array, order, orderBy) {
    if (order == null) {
      order = 'asc';
    }
    if (orderBy == null) {
      orderBy = 'displayName';
    }
    const orderConst = order === 'desc' ? 1 : -1;
    const orderByKey = orderBy;
    if (array == null || array.length == 0) {
      return [];
    }
    if (array.length == 1) {
      return array;
    }
    return array.sort((a, b) => {
      let ele1 = a[orderByKey];
      let ele2 = b[orderByKey];
      if (!ele1 && !ele2) {
        return 0;
      }
      if (ele1 && !ele2) {
        return orderConst;
      }
      if (ele2 && !ele1) {
        return -orderConst;
      }
      if (typeof ele1 === 'string' || typeof ele2 === 'string') {
        return ele1.localeCompare(ele2) * orderConst;
      }
      return (ele1 - ele2) * orderConst;
    });
  }

  renderStatus = () => {
    const { classes, status } = this.props;
    const Icon = status.success ? CheckCircleIcon : ErrorIcon;
    if (status.message) {
      return (
        <div
          className={classNames(classes.statusMsg, {
            [classes.success]: status.success,
            [classes.error]: !status.success,
          })}
        >
          <Icon className={status.success ? classes.success : classes.error} />
          {status.message}
        </div>
      );
    }
    return null;
  };

  renderSearchBar = () => {
    const { classes } = this.props;
    return (
      <Paper>
        <InputBase
          fullWidth
          onKeyDown={this.onSearchEnter}
          onChange={this.onSearchChange}
          className={classes.input}
          placeholder="Name or Email"
          inputProps={{ 'aria-label': 'Name or Email' }}
          endAdornment={
            <IconButton className={classes.iconButton} aria-label="search" onClick={this.onSearchClick}>
              <SearchIcon />
            </IconButton>
          }
        />
      </Paper>
    );
  };
  renderSortIcon(sortByField) {
    const { order, orderBy } = this.state;
    if (orderBy === sortByField) {
      if (order == 'asc') {
        return <ArrowUpwardIcon fontSize="small" />;
      } else {
        return <ArrowDownwardIcon fontSize="small" />;
      }
    }
  }

  exportToCSV = async () => {
    const { users } = this.props;
    const { showInactive } = this.state;
    if (!users) return;

    // Convert object to array
    const usersArray = Object.values(users);

    // Define CSV headers UID,
    const csvColumns = [
      'Display Name',
      'Email',
      'Phone Number',
      'District Number',
      'Campus Number',
      'Role',
      'Deactivated',
      'Last Login',
    ];
    const filteredUsers = usersArray.filter((user) => showInactive || !user.deactivated);
    const data = filteredUsers.map((user) => ({
      // 'userId':user.uid,
      'Display Name': user.displayName,
      Email: user.emailAddress,
      'Phone Number': user.phoneNumber,
      'District Number': user.districtNumber || '', // Handle missing values
      'Campus Number': user.campusNumber || '',
      Role: user.role,
      Deactivated: user.deactivated ? 'Yes' : 'No',
      'Last Login': user.lastLogin,
    }));

    if (data.lastLogin) {
      data.lastLogin = moment
        .utc(data.lastLogin)
        .tz('America/New_York')
        .format('MM-DD-YYYY hh:mm A z'); // Convert to New York time
    }

    const output = await makeCsv(data, csvColumns);
    const blob = new Blob([output], { type: 'text/csv;charset=utf-8;' });
    if (blob) {
      const timestamp = new Date().toISOString().replace(/[-T:.Z]/g, '');
      const fileName = `users_${timestamp}.csv`;
      saveAs(blob, fileName);
    }

    // Map user data to CSV format
    // const rows = usersArray.map((user) =>
    //   [
    //     // user.uid,
    //     user.displayName,
    //     user.emailAddress,
    //     user.phoneNumber,
    //     user.districtNumber || '', // Handle missing values
    //     user.campusNumber || '',
    //     user.role,
    //     user.deactivated || false,
    //     ,
    //   ].join(',')
    // );

    // Join headers and rows
    // const csvContent = [headers, ...rows].join('\n');
    // Create and download CSV file
    // const blob = new Blob([csvContent], { type: 'text/csv' });
    // const url = URL.createObjectURL(blob);
    // const link = document.createElement('a');
    // link.href = url;
    // const timestamp = new Date().toISOString().replace(/[-T:.Z]/g, '_');

    // link.download = fileName;
    // link.click();
    // URL.revokeObjectURL(url);
  };

  render() {
    const { classes, profile } = this.props;
    const { openInviteModal, order, orderBy, searchedUsers, enableDisableViewReportsValue, showInactive } = this.state;
    const userTableHeaders = ['Name', 'Email', 'Role', 'Phone', 'Status', 'Last Login'];
    const userTableHeadersColumns = ['displayName', 'emailAddress', 'role', 'phoneNumber', 'status', 'lastLogin'];

    // if (!users && !invitedUsers) {
    //   return (
    //     <Typography variant="h6" gutterBottom>
    //       You are the only user in your organization. Start by inviting admins to your organization.
    //     </Typography>
    //   );
    // }
    return (
      <React.Fragment>
        {!searchedUsers && (
          <Typography variant="h6" gutterBottom>
            You are the only user in your organization. Start by inviting admins to your organization.
          </Typography>
        )}

        {this.renderSearchBar()}
        <p></p>

        <Grid container alignItems="center">
          <Grid item>
            <Button variant="contained" onClick={this.toggleInactiveUsers}>
              {this.state.showInactive ? 'Hide Inactive Users' : 'Show Inactive Users'}
            </Button>
          </Grid>
          <Grid item xs>
            <div style={{ textAlign: 'right' }}>
              <ExportCSVButton title="Export CSV" onClick={this.exportToCSV} />
            </div>
          </Grid>
        </Grid>

        <Grid container direction="column" justify="flex-start" alignItems="center">
          {this.renderStatus()}
          {openInviteModal && <UpdateAddUser handleUpdateClose={this.handleCloseModal} />}
          <Grid container direction="row" justify="space-between" alignItems="center" item>
            {userTableHeaders.map((header, index) => (
              <div
                onClick={this.onSortChange(userTableHeadersColumns[index])}
                key={header}
                className={classNames(classes.cell, classes.headerCell, {
                  [classes.smallCell]: index === 3 || index === 4,
                  [classes.textCell]: index !== 3 && index !== 4,
                })}
                style={{ background: index % 2 ? '#effdff' : '#f8ffff', cursor: 'pointer' }}
              >
                {header}
                {this.renderSortIcon(userTableHeadersColumns[index])}
              </div>
            ))}
            <div className={classNames(classes.cell, classes.iconCell, classes.headerCell)}>
              <IconButton onClick={this.handleOpenModal}>
                <PersonAdd />
              </IconButton>
            </div>
          </Grid>
          {searchedUsers &&
            searchedUsers.length > 0 &&
            this.sortBy(searchedUsers, order, orderBy).map((user) => (
              <User
                profile={profile}
                isInvitedUser={user.isInvitedUser}
                key={user.firebaseId}
                firebaseId={user.firebaseId}
                user={user}
              />
            ))}
        </Grid>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(ManageUsers);
