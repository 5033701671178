import { combineReducers } from 'redux';
import {
  ENABLE_VIEW_REPORTS_FOR_ALL_USERS,
  GET_DISTRICT_ADVANCED_SETTINGS,
  SAVE_DISTRICT_ADVANCED_SETTINGS,
} from './AdvancedSettings.actions';
import { ASYNC_ERROR, ASYNC_FINISH, ASYNC_START } from '../../../common';
import { mainMenuItems } from '../../../common/utils';

const initialStatus = {
  success: false,
  message: '',
};

const canViewReports = (state = null, action) => {
  if (action.type === ASYNC_FINISH) {
    if (action.type === ASYNC_FINISH) {
      if (action.model === ENABLE_VIEW_REPORTS_FOR_ALL_USERS) {
        return action.data;
      }
    }
  }
  return state;
};
const status = (state = initialStatus, action) => {
  const isManageUsersModel = [ENABLE_VIEW_REPORTS_FOR_ALL_USERS].includes(action.model);
  switch (action.type) {
    case ASYNC_START:
      if (isManageUsersModel) {
        return initialStatus;
      }
      return state;
    case ASYNC_ERROR:
      if (isManageUsersModel) {
        return {
          success: false,
          message:
            action.error && (action.error.message || action.error.code)
              ? action.error.message || action.error.code
              : 'Error: Something went wrong!',
        };
      }
      return state;
    case ASYNC_FINISH:
      if (action.model === ENABLE_VIEW_REPORTS_FOR_ALL_USERS) {
        return {
          success: true,
          message: action.data.result.val ? 'All users may now view reports.' : 'All users can no longer view reports.',
        };
      }
      if (action.model === SAVE_DISTRICT_ADVANCED_SETTINGS) {
        const menuKey = action.data.menuName;
        const menuTitle = menuKey ? (mainMenuItems[menuKey] ? mainMenuItems[menuKey].title : menuKey) : '';
        return {
          success: true,
          message: action.data.isVisible
            ? `The "${menuTitle}" menu has been made visible.`
            : `The "${menuTitle}" menu has been hidden.`,
        };
      }
      return state;
    default:
      return state;
  }
};
const districtAdvancedSettings = (state = {}, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_DISTRICT_ADVANCED_SETTINGS) {
        return action.data;
      }
      return state;
    default:
      return state;
  }
};

const saveDistrictAdvancedSettingsResult = (state = [], action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === SAVE_DISTRICT_ADVANCED_SETTINGS) {
        return action.data.message;
      }
      return state;
    default:
      return state;
  }
};

export const _reducer = combineReducers({
  status,
  canViewReports,
  districtAdvancedSettings,
  saveDistrictAdvancedSettingsResult,
});
export default 'AdvancedSettings.reducers.js';
