import { combineReducers } from 'redux';
import {
  GET_ORG_USERS,
  GET_INVITED_USERS,
  CANCEL_INVITE_USER,
  INVITE_USER_TO_ORG,
  ACTIVATE_ACCOUNT,
  DEACTIVATE_ACCOUNT,
  RESET_USER_PASSWORD,
  UPDATE_USER_PROFILE,
  RESEND_INVITE_USER,
} from './ManageUsers.actions';
import { ASYNC_ERROR, ASYNC_FINISH, ASYNC_START } from '../../../common';

const users = (state = null, action) => {
  if (action.type === ASYNC_FINISH) {
    if (action.model === GET_ORG_USERS) {
      return action.data;
    }
    if (
      action.model === UPDATE_USER_PROFILE ||
      action.model === ACTIVATE_ACCOUNT ||
      action.model === DEACTIVATE_ACCOUNT
    ) {
      const uid = action.data.userUid;
      state[uid] = Object.assign({}, action.data.userData);
      return Object.assign({}, state);
    }
  }

  return state;
};

const invitedUsers = (state = null, action) => {
  if (action.type === ASYNC_FINISH) {
    if (action.model === GET_INVITED_USERS) {
      return action.data;
    }
    if (action.model === CANCEL_INVITE_USER) {
      const { signUpCode } = action.data;
      delete state[signUpCode];
      return Object.assign({}, state);
    }
    if (action.model === INVITE_USER_TO_ORG) {
      const { signUpCode, userData } = action.data;
      state[signUpCode] = userData;
      return Object.assign({}, state);
    }
  }
  return state;
};

const initialStatus = {
  success: false,
  message: '',
};

const status = (state = initialStatus, action) => {
  const isManageUsersModel = [
    CANCEL_INVITE_USER,
    INVITE_USER_TO_ORG,
    ACTIVATE_ACCOUNT,
    DEACTIVATE_ACCOUNT,
    RESET_USER_PASSWORD,
    UPDATE_USER_PROFILE,
    RESEND_INVITE_USER,
  ].includes(action.model);
  switch (action.type) {
    case ASYNC_START:
      if (isManageUsersModel) {
        return initialStatus;
      }
      return state;
    case ASYNC_ERROR:
      if (isManageUsersModel) {
        return {
          success: false,
          message:
            action.error && (action.error.message || action.error.code)
              ? action.error.message || action.error.code
              : 'Error: Something went wrong!',
        };
      }
      return state;
    case ASYNC_FINISH:
      if (action.model === RESEND_INVITE_USER) {
        return {
          success: true,
          message: 'User`s invite has been resent.',
        };
      }
      if (action.model === CANCEL_INVITE_USER) {
        return {
          success: true,
          message: 'User`s invite has been revoked.',
        };
      }
      if (action.model === INVITE_USER_TO_ORG) {
        return {
          success: true,
          message: `${action.data.userData.emailAddress} has been invited to the organization.`,
        };
      }
      if (action.model === ACTIVATE_ACCOUNT) {
        return {
          success: true,
          message: `The account of ${action.data.userData.emailAddress} has been activated.`,
        };
      }
      if (action.model === DEACTIVATE_ACCOUNT) {
        return {
          success: true,
          message: `The account of ${action.data.userData.emailAddress} has been deactivated.`,
        };
      }
      if (action.model === UPDATE_USER_PROFILE) {
        return {
          success: true,
          message: `The profile of ${action.data.userData.emailAddress} has been updated.`,
        };
      }
      if (action.model === RESET_USER_PASSWORD) {
        return {
          success: true,
          message: `The password of ${action.data.userData.emailAddress} has been reset.`,
        };
      }
      return state;
    default:
      return state;
  }
};

export const _reducer = combineReducers({
  users,
  invitedUsers,
  status,
});
export default 'ManageUsers.reducers.js';
