import { connect } from 'react-redux';
import Component from './HomePage.component';
import { campusDistrictInfo } from '../Settings/+store/SchoolSettings/SchoolSettings.selectors';
import { getDistrictAdvancedSettings } from '../Settings/+store/AdvancedSettings';

const mapStateToProps = (state) => ({
  campusDistrictInfo: campusDistrictInfo(state),
  profile: state.modules.Auth.auth.profile,
  districtAdvancedSettings: state.modules.Settings.advancedSettings.districtAdvancedSettings,
});

const mapDispatchToProps = (dispatch) => ({
  init: (profile) => {
    dispatch(getDistrictAdvancedSettings(profile));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
