import { connect } from 'react-redux';
import { getOrganizationUsers, getInvitedUsers } from '../+store/ManageUsers';
import Component from './ManageUsers.component';

const mapStateToProps = (state) => ({
  users: state.modules.Settings.manageUsers.users,
  invitedUsers: state.modules.Settings.manageUsers.invitedUsers,
  status: state.modules.Settings.manageUsers.status,
  profile: state.modules.Auth.auth.profile,
});

const mapDispatchToProps = (dispatch) => ({
  init: () => {
    dispatch(getOrganizationUsers());
    dispatch(getInvitedUsers());
  },
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
