import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';
import { _reducer as settings } from './Settings.reducers';
import { _reducer as accountSettings, epics as accountSettingsEpic } from './AccountSettings';
import { _reducer as schoolSettings, epics as schoolSettingsEpic } from './SchoolSettings';
import { _reducer as advancedSettings, epics as advancedSettingsEpic } from './AdvancedSettings';
import { _reducer as manageUsers, epics as manageUsersEpic } from './ManageUsers';
import { _reducer as manageOrgTags, epics as manageOrgTagsEpic } from './ManageOrgTags';

export const reducer = combineReducers({
  accountSettings,
  schoolSettings,
  advancedSettings,
  manageUsers,
  manageOrgTags,
  settings,
});

export const epics = combineEpics(
  accountSettingsEpic,
  schoolSettingsEpic,
  advancedSettingsEpic,
  manageUsersEpic,
  manageOrgTagsEpic
);
