import { connect } from 'react-redux';
import Component from './AdvancedSettings.component';
import {
  enableViewReportsForAllUsers,
  getDistrictAdvancedSettings,
  saveDistrictAdvancedSettings,
} from '../+store/AdvancedSettings';
import { getOrganizationUsers } from '../+store/ManageUsers';
import { getDistrictOrSchoolData } from '../+store/SchoolSettings/SchoolSettings.actions';
// import { getSchools } from '../../Settings/+store/SchoolSettings';

const mapStateToProps = (state) => ({
  status: state.modules.Settings.advancedSettings.status,
  profile: state.modules.Auth.auth.profile,
  districtAdvancedSettings: state.modules.Settings.advancedSettings.districtAdvancedSettings,
});

const mapDispatchToProps = (dispatch) => ({
  init: (profile) => {
    if (profile) {
      dispatch(getDistrictAdvancedSettings(profile));
    }
  },
  saveDistrictAdvancedSettings: (profile, settings) => {
    dispatch(saveDistrictAdvancedSettings(profile, settings));
    dispatch(getDistrictOrSchoolData({ campusNumber: null }));
  },
  getDistrictAdvancedSettings: (profile) => {
    dispatch(getDistrictAdvancedSettings(profile));
  },
  disableViewReportsForAllUsersEpic: () => {
    dispatch(enableViewReportsForAllUsers(false));
    dispatch(getOrganizationUsers());
  },
  enableViewReportsForAllUsersEpic: () => {
    dispatch(enableViewReportsForAllUsers(true));
    dispatch(getOrganizationUsers());
  },
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
