import owasp from 'owasp-password-strength-test';
import ErrorIcon from '@material-ui/icons/Cancel';
import * as React from 'react';
import firebase from 'firebase';
import stringify from 'csv-stringify';

import { SvgIcon } from '@material-ui/core';
import CCMRTrackerIcon from '@material-ui/icons/School';
import SettingsIcon from '@material-ui/icons/Settings';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import GroupIcon from '@material-ui/icons/Group';
import FlightIcon from '@material-ui/icons/Flight';
import GradeIcon from '@material-ui/icons/Grade';
import { Routes } from '../App/App.routes';

export const isRealNumber = (num) => num != null && !Number.isNaN(Number(num));

export const passwordStrengthGrade = (password) => {
  const strength = owasp.test(password || '');
  const width = (5 - strength.errors.length) / 5;
  const colors = '#68D190';

  const style = {
    root: {
      backgroundColor: 'transparent',
      width: '370px',
      marginBottom: 10,
      minWidth: '370px',
    },
    progress: {
      width: width * 75 > 0 ? `${width * 100}%` : '0%',
      height: 8,
      backgroundColor: password.length > 0 ? colors : null,
    },
    errorMsgZone: {
      color: '#EE9696',
      fontSize: '0.875rem',
      marginBottom: '8px',
      paddingLeft: 8,
      alignItems: 'center',
      display: 'flex',
    },
    errorIcon: {
      fontSize: 20,
      marginRight: 5,
    },
  };
  return (
    <div style={style.root}>
      <div style={style.progress} />
      {strength.errors.map((error, index) => (
        <div style={style.errorMsgZone} key={index}>
          <ErrorIcon style={style.errorIcon} />
          {error}
        </div>
      ))}
    </div>
  );
};

export const isMultipleCampusAdmin = (user) =>
  user.role === 'campusAdmin' && user.campusNumber && typeof user.campusNumber !== 'string';

export const isCampusAdminForThisCampus = (user, campusNumber) => {
  if (!user.campusNumber) {
    return false;
  }
  if (typeof user.campusNumber === 'string') {
    return user.campusNumber === campusNumber;
  }
  return user.campusNumber.some((campus) => campus === campusNumber);
};

export const getMultipleCampusData = async (user, databaseRefPath, orderByChild = true) => {
  let multipleCampusData = {};
  await Promise.all(
    user.campusNumber.map(async (campus) => {
      let campusData = null;
      if (orderByChild) {
        campusData = await firebase
          .database()
          .ref(databaseRefPath)
          .orderByChild('campusNumber')
          .equalTo(campus)
          .once('value')
          .then((snapshot) => snapshot.val());
      } else {
        campusData = await firebase
          .database()
          .ref(`${databaseRefPath}/${campus}`)
          .once('value')
          .then((snapshot) => snapshot.val());
      }
      multipleCampusData[campus] = campusData;
    })
  );

  return multipleCampusData;
};
function WeightsIcon(props) {
  return (
    <SvgIcon viewBox="0 0 44 32" width="44" height="32" {...props}>
      <path d="M7.49 5.07001L0 22.88C0 22.88 7.95 28.48 15.52 22.64L7.49 5.07001Z" fill="#656565" />
      <path d="M35.2 5.07001L27.71 22.88C27.71 22.88 35.66 28.48 43.23 22.64L35.2 5.07001Z" fill="#656565" />
      <path d="M41.1099 0.25H2.40991V2.81H41.1099V0.25Z" fill="#656565" />
      <path d="M30.02 29.27H12.78V31.73H30.02V29.27Z" fill="#656565" />
      <path d="M23 1.53003H20.22V31.55H23V1.53003Z" fill="#656565" />
    </SvgIcon>
  );
}
export const makeCsv = (shapedData, columns) =>
  new Promise((resolve, reject) => {
    stringify(
      shapedData,
      {
        columns,
        record_delimiter: 'windows',
        header: true,
        delimiter: ',',
      },
      (err, output) => {
        if (err) {
          console.log(err);
          reject(err);
        }
        resolve(output);
      }
    );
  });

export const mainMenuItems = {
  studentRoster: {
    title: 'Student Roster',
    isDistrictAdminOnly: false,
    icon: GroupIcon,
    isVisibilityEditable: false,
    link: `/${Routes.studentRoster}`,
  },
  reports: {
    title: 'A-F Reports',
    isVisibleToDistrictOnly: false,
    icon: AssessmentOutlinedIcon,
    isVisibilityEditable: true,
    link: `/${Routes.aToF}`,
  },
  weights: {
    title: 'Accountability Weights',
    isVisibleToDistrictOnly: false,
    icon: WeightsIcon,
    isVisibilityEditable: true,
    link: `/${Routes.weights}`,
  },
  progressGoals: {
    title: 'Progress Goals',
    isVisibleToDistrictOnly: false,
    icon: GradeIcon,
    isVisibilityEditable: true,
    link: `/${Routes.staar}`,
  },
  flightPlan: {
    title: 'Flight Plan',
    isVisibleToDistrictOnly: false,
    icon: FlightIcon,
    isVisibilityEditable: true,
    link: `/${Routes.flightPlan}`,
  },
  ccmrTracking: {
    title: 'CCMR Tracking',
    isVisibleToDistrictOnly: true,
    icon: CCMRTrackerIcon,
    isVisibilityEditable: true,
    link: `/${Routes.ccmrTracking}`,
  },
  assessments: {
    title: 'Assessment Manager',
    isVisibleToDistrictOnly: false,
    icon: AssignmentIcon,
    isVisibilityEditable: false,
    link: `/${Routes.assessments}`,
  },
  settings: {
    title: 'Settings',
    isVisibleToDistrictOnly: false,
    icon: SettingsIcon,
    isVisibilityEditable: false,
    link: `/${Routes.settings}`,
  },
};

export default '';
