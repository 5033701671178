require('dotenv').config();

export const Routes = {
  onboarding: 'onboarding',
  signIn: 'sign-in',
  welcome: 'welcome',
  studentUpload: 'students',
  validation: 'validation',
  takeOff: 'take-off',
  versionNumber: 'version-number',
  roster: 'roster',
  newApp: 'app',
  aToF: 'a-f',
  assessments: 'assessments',
  studentRoster: 'student-roster',
  settings: 'settings',
  advancedSettings: 'advanced-settings',
  campusSettings: 'campus',
  accountSettings: 'account',
  userSettings: 'manage-users',
  orgTagsSettings: 'manage-org-tags',
  weights: 'accountability-weights',
  flightPlan: 'flight-plan',
  signUp: 'sign-up',
  resetPassword: 'reset-password',
  forgotPassword: 'forgot-password',
  staar: 'progress-goals',
  ccmrTracking: 'ccmr-tracking',
};

export default '';
