import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Cancel';
import {
  Grid,
  FormControlLabel,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@material-ui/core';
import styles from './AdvancedSettings.styles';
import { mainMenuItems } from '../../common/utils';

class AdvancedSettings extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    init: PropTypes.func,
    status: PropTypes.shape({
      success: PropTypes.bool,
      message: PropTypes.string,
    }),
    profile: PropTypes.object,
    saveDistrictAdvancedSettings: PropTypes.func,
    getDistrictAdvancedSettings: PropTypes.func,
    districtAdvancedSettings: PropTypes.object,
    disableViewReportsForAllUsersEpic: PropTypes.func,
    enableViewReportsForAllUsersEpic: PropTypes.func,
    districtAdvancedSettings: PropTypes.object,
  };

  state = {
    viewReportsForAllUsersSelectedValueTmp: false,
    viewReportsForAllUsersValue: false,
    openViewReportsForAllUserConfirmDialog: false,

    advancedSettingSelectedNameValueTmp: null,
    advancedSettingSelectedNameValue: null,
    openAdvancedSettingsConfirmDialog: false,
    menusVisibility: mainMenuItems
      ? Object.fromEntries(
          Object.entries(mainMenuItems)
            .filter(([key, item]) => item.isVisibilityEditable) // Filter only those with isVisibilityEditable == true
            .map(([key, item]) => [key, item.isVisibilityEditable]) // Map to keep the key-value pair
        )
      : {},
  };

  handleOpenViewReportsForAllUsersDialog = (event) => {
    this.setState({
      openViewReportsForAllUserConfirmDialog: true,
      viewReportsForAllUsersSelectedValueTmp: event.target.checked,
    });
  };

  handleViewReportsForAllUsersConfirmChange = () => {
    const { enableViewReportsForAllUsersEpic, disableViewReportsForAllUsersEpic } = this.props;
    const { viewReportsForAllUsersSelectedValueTmp } = this.state;

    this.setState({
      viewReportsForAllUsersValue: viewReportsForAllUsersSelectedValueTmp,
      openViewReportsForAllUserConfirmDialog: false,
    });

    if (viewReportsForAllUsersSelectedValueTmp === true) {
      enableViewReportsForAllUsersEpic();
    } else {
      disableViewReportsForAllUsersEpic();
    }
  };

  handleCloseViewReportsForAllUsersDialog = () => {
    this.setState({ openViewReportsForAllUserConfirmDialog: false });
  };

  // Handle checkbox change (but wait for confirmation)
  handleAdvancedSettingsCheckboxChange = (event) => {
    const { name, checked } = event.target;
    this.setState({
      advancedSettingSelectedNameValueTmp: { name, checked }, // Store the setting that was changed
      openAdvancedSettingsConfirmDialog: true, // Open confirmation dialog
    });
  };

  // Handle confirmation response
  handleAdvancedSettingsConfirmChange = (confirmed) => {
    if (confirmed && this.state.advancedSettingSelectedNameValueTmp) {
      const { name, checked } = this.state.advancedSettingSelectedNameValueTmp;

      this.setState((prevState) => ({
        menusVisibility: {
          ...prevState.menusVisibility,
          [name]: checked, // Apply change after confirmation
        },
        openAdvancedSettingsConfirmDialog: false,
        advancedSettingSelectedNameValue: this.state.advancedSettingSelectedNameValueTmp,
      }));

      // Dispatch action to save settings (if using Redux)
      const { saveDistrictAdvancedSettings, profile } = this.props;
      saveDistrictAdvancedSettings(profile, { menusVisibility: { [name]: checked } });
    } else {
      this.setState({ openAdvancedSettingsConfirmDialog: false, advancedSettingSelectedNameValueTmp: null });
    }
  };

  renderStatus = () => {
    const { classes, status } = this.props;
    const Icon = status.success ? CheckCircleIcon : ErrorIcon;
    if (status.message) {
      return (
        <div
          className={classNames(classes.statusMsg, {
            [classes.success]: status.success,
            [classes.error]: !status.success,
          })}
        >
          <Icon className={status.success ? classes.success : classes.error} />
          {status.message}
        </div>
      );
    }
    return null;
  };

  componentDidMount() {
    const { init, profile } = this.props;
    if (profile) {
      init(profile);
    }
  }
  componentDidUpdate(prevProps) {
    const { profile, init, districtAdvancedSettings } = this.props;

    // Call init when profile becomes available
    if (!prevProps.profile && profile) {
      init(profile);
    }

    // Compare deeply nested properties inside districtAdvancedSettings
    if (prevProps.districtAdvancedSettings != districtAdvancedSettings) {
      if (districtAdvancedSettings != null) {
        // Merge existing settings with defaults (preserve unchecked ones)
        const updatedMenusVisibility = {
          ...this.state.menusVisibility, // Default settings
          ...districtAdvancedSettings.menusVisibility, // Override with backend data
        };
        this.setState({
          menusVisibility: updatedMenusVisibility,
          viewReportsForAllUsersValue: districtAdvancedSettings.canViewAccountabilityReportsPerUser,
        });
      }
    }
  }

  render() {
    const { profile } = this.props;
    const {
      viewReportsForAllUsersValue,
      openViewReportsForAllUserConfirmDialog,
      menusVisibility,
      openAdvancedSettingsConfirmDialog,
      advancedSettingSelectedNameValueTmp,
    } = this.state;

    return (
      <React.Fragment>
        {this.renderStatus()}
        <Grid container direction="column">
          {profile && profile.isSuperAdmin === true && (
            <FormControl component="fieldset">
              <FormLabel component="legend">View Reports for All Users</FormLabel>

              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={viewReportsForAllUsersValue} // Boolean state
                    onChange={this.handleOpenViewReportsForAllUsersDialog} // Open confirmation dialog
                    name="viewReports"
                  />
                }
                label="Enable View Reports for All Users"
              />
            </FormControl>
          )}
        </Grid>
        <Dialog open={openViewReportsForAllUserConfirmDialog} onClose={this.handleCloseViewReportsForAllUsersDialog}>
          <DialogTitle>Confirm reports visibility for all users</DialogTitle>
          <DialogContent>
            Are you sure you want to {this.state.viewReportsForAllUsersSelectedValueTmp ? 'ENABLE' : 'DISABLE'} view
            reports for all users?
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseViewReportsForAllUsersDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleViewReportsForAllUsersConfirmChange} color="primary" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        <Grid container direction="column" spacing={8} style={{ marginTop: '16px' }}>
          <FormLabel component="legend">Menu Visibility </FormLabel>
          {menusVisibility &&
            Object.entries(menusVisibility).map(([key, value]) => (
              <Grid item key={key}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={value}
                      onChange={this.handleAdvancedSettingsCheckboxChange}
                      name={key}
                    />
                  }
                  label={mainMenuItems[key] ? mainMenuItems[key].title : key}
                />
              </Grid>
            ))}

          <Dialog
            open={openAdvancedSettingsConfirmDialog}
            onClose={() => this.handleAdvancedSettingsConfirmChange(false)}
          >
            <DialogTitle>Confirm Menu Visibility</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to{' '}
                {advancedSettingSelectedNameValueTmp && advancedSettingSelectedNameValueTmp.checked
                  ? 'ENABLE'
                  : 'DISABLE'}{' '}
                "
                {advancedSettingSelectedNameValueTmp
                  ? mainMenuItems[advancedSettingSelectedNameValueTmp.name]
                    ? mainMenuItems[advancedSettingSelectedNameValueTmp.name].title
                    : advancedSettingSelectedNameValueTmp.name
                  : ''}
                " ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.handleAdvancedSettingsConfirmChange(false)}
                style={{ backgroundColor: 'darkgray', color: 'white' }}
              >
                Cancel
              </Button>
              <Button onClick={() => this.handleAdvancedSettingsConfirmChange(true)} color="dark">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(AdvancedSettings);

// handleAdvancedSettingsCheckboxChange = (event) => {
//   const { name, checked } = event.target;
//   this.setState((prevState) => ({
//     menusVisibility: {
//       ...prevState.menusVisibility,
//       [name]: checked,
//     },
//   }));
// };

// handleSaveDistrictAdvancedSettings = () => {
//   // Dispatch Epic/Reducer to save settings
//   const { saveDistrictAdvancedSettings } = this.props;
//   saveDistrictAdvancedSettings({ menusVisibility: this.state.menusVisibility });
// };
// handleViewReportsForAllUsersOptionChange(event) {
//   const { enableViewReportsForAllUsersEpic, disableViewReportsForAllUsersEpic } = this.props;
//   this.setState({ viewReportsForAllUsersValue: event.target.value });
//   if (event.target.value === 'enable') {
//     enableViewReportsForAllUsersEpic();
//   } else {
//     disableViewReportsForAllUsersEpic();
//   }
// }
