import { db } from '../../../App/+store/firebase';

export const saveDistrictAdvancedSettings = async (profile, data) => {
  if (profile && profile.isSuperAdmin == true && profile.role === 'districtAdmin') {
    const orgId = profile.organizationId;
    try {
      if (data) {
        await db
          .ref(`newDatabase/organizations/${orgId}/district/advancedSettings/menusVisibility`)
          .update(data.menusVisibility);
      }
    } catch (err) {
      console.error('Error: ', err);
    }
  } else {
    throw 'You do not have permissions to save district advanced settings';
  }
};

export const getDistrictAdvancedSettings = async (profile) => {
  const orgId = profile.organizationId;
  const districtSettings = (
    await db.ref(`newDatabase/organizations/${orgId}/district/advancedSettings`).once('value')
  ).val();
  return districtSettings;
};
